.main_section {
  background-color: #f3f4f6;
}

.bill-modal-open {
  overflow: hidden;
  max-height: 100vh;
}

.billing_card {
  background-color: #ffffff; 
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
  position: relative;
  max-width: 28rem;
  width: 100%;
  border-radius: 0rem !important;
  text-align: center; /* Equivalent to text-center in Tailwind CSS */
  padding: 2rem; /* Equivalent to p-[2rem] in Tailwind CSS */
}

.custom-shadow {
 
  background-color: #ffffff; 
    border-radius: 0.5rem;
    box-shadow: 
      0 20px 8px rgba(140, 140, 140, 0.1), 
      8px 0 8px rgba(140, 140, 140, 0.1), 
      0 -8px 20px rgba(179, 179, 179, 0.1), 
      -8px 0 8px rgba(140, 140, 140, 0.1); 
  /* transition: box-shadow 0.3s ease;  */
}

.most_popular {
  position: absolute;
  min-width: max-content;
  background-image: linear-gradient(to right, #7ff7ad, #5db2f7);
  top: -20px;
  padding: 0.5rem;
  padding-left: 2rem;
  padding-right: 2rem;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 9999px;
  color: #000;
  font-weight: 400;
  background-clip: padding-box;
}

.most_popular_selected {
  margin-top: 20px;
}
.grid_box {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  column-gap: 1.25rem;
  row-gap: 1.5rem;
  padding: 0.75rem;
}

.main_box {
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  padding: 0.75rem;
}
.main_box_subscribed {
  width: fit-content;
  padding: 0.75rem;
}

.subscribe_btn {
  position: absolute;
  min-width: max-content;
  border-radius: 4rem !important;
  bottom: 3rem;
  font-size: 14px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  left: 50%;
  transform: translateX(-50%);
  padding-left: 3rem;
  padding-right: 3rem;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
}
/* PT Changes */
.heading_text_billing {
  font-size: 1.5rem !important;
  margin-top: 2rem;
}
.p_text_billing {
  /* font-size: 20pt; */
  color: #919191 !important;
}

.margin_box {
  margin-top: 1rem;
  margin-bottom: 7rem;
}
.mo_section {
  margin-top: 1.5rem;
  margin-left: 0.25rem; /* Considering 1 unit in Tailwind CSS is equal to 0.25rem */
  display: flex;
  align-items: center;
}

.price_box {
  /* font-size: 2.25rem; */
  font-size: 3rem;
  margin-top: 0.5rem; /* Equivalent to my-2 in Tailwind CSS */
  margin-bottom: 0.5rem; /* Equivalent to my-2 in Tailwind CSS */
}
.dollar_box {
  margin-top: 1rem !important;
  font-weight: 300; /* Equivalent to font-light in Tailwind CSS */
  font-size: 1.5rem !important; /* Equivalent to text-2xl in Tailwind CSS */
}
.main_price_box {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  border-bottom: 1px solid #cbd5e0; /* Assuming default gray-400 in Tailwind */
  /* padding-bottom: 1rem; */
}
.pkg_name {
  font-size: 1rem;
  letter-spacing: 0.05em;
}

.billing_card {
  padding-bottom: 3rem;
}

.payment-plans-modal {
  /* max-width: 1500px !important; */
  margin: auto;
  margin-top: 2rem;
  margin-bottom: 2rem;
  max-height: max-content !important;
  min-height: max-content !important;
  max-width: max-content !important;
}

.modal-body-plans {
  padding: 20px !important;
  max-height: max-content !important;
  min-height: max-content !important;
  background-color: rgb(252, 252, 252) !important;
}

@media (min-width: 1024px) {
  .modal-body-plans {
    padding: 70px !important;
  }
  .billing_card {
    padding: 3rem;
    padding-bottom: 7rem;
  }
  .grid_box {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .main_box {
    padding: 1rem;
  }
  .margin_box {
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
  .heading_text_billing {
    font-size: 2.0625rem !important;
    margin-top: 0rem;
  }
  .most_popular_selected {
    margin-top: 0rem !important;
  }
}
