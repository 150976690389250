$ff-bold: 'CircularStdBold';
$ff-book: 'CircularStdBook';
$ff-light: 'CircularSpotifyTxT-Light';
$ff-medium: 'CircularStdMedium';
$ff-inter-regular: 'Inter-Regular';
$ff-inter-medium: 'Inter-Medium';
$ff-inter-semi: 'Inter-SemiBold';

.bg-dark {
    background: #000 !important;
}
html {
    overflow-x: hidden;
}
body {
    background: #fff;
    overflow-x: hidden !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: top center !important;
    width: 100%;
    -webkit-text-size-adjust: 100% !important;
    font-family: $ff-inter-semi !important;

    .bg-hide {
        background: #e1dbdb !important;
        opacity: 0.14 !important;
        pointer-events: none;
    }

    .bg-hide-code {
        opacity: 0.14 !important;
        pointer-events: none;
    }
}

.modal-open {
    padding-right: 0px !important;
}

.ff-bold {
    font-family: $ff-bold;
}

.ff-medium {
    font-family: $ff-medium;
}

.ff-book {
    font-family: $ff-book;
}

.ff-light {
    font-family: $ff-light;
}

.ff-inter-regular {
    font-family: $ff-inter-regular !important;
}

.ff-inter-medium {
    font-family: $ff-inter-medium !important;
}

.ff-inter-semi {
    font-family: $ff-inter-semi !important;
}

.text-gray {
    color: #7C7C7C !important;
}

.text-dark {
    color: #000 !important;
}

.font-style {
    font-size: 20px;
    line-height: 30px;
    letter-spacing: -0.625px;
}

.font-banner {
    font-size: 53px;
    font-weight: 900 !important;
    letter-spacing: 5px;
}

.font-banner-research {
    font-size: 40px;
    font-weight: 900 !important;
    letter-spacing: 5px;
}

.font-banner-small {
    font-size: 50px;
    font-weight: 900 !important;
    letter-spacing: 5px;
}

.font-subhead {
    font-size: 36px;
    font-weight: 400;
    letter-spacing: -0.9px;
    line-height: 48px;
}

.work-head {
    font-size: 3rem !important;
    letter-spacing: -1.2px !important;
    line-height: 56px !important;
}

.font-gradient-text {
    font-size: 0.875rem !important;
    letter-spacing: 4px !important;
}

.nav-text {
    font-size: 20px;
    font-weight: 400;
    color: #7C7C7C;
    letter-spacing: -0.5px;
}

.nav-link-space {

    .nav-text:hover,
    .navtext-active {
        background: -webkit-linear-gradient(#00F795, #00C4E9);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 20px;
        font-weight: 400;
    }
}

.text-banner {
    background: -webkit-linear-gradient(#00F795, #00C4E9) !important;
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
}

.bg-btn {
    border: 2px solid #00F795;
    border-radius: 30px;
    font-size: 20px;
    width: fit-content;
}

.bg-connect {
    border: 2px dashed #00F795;
    border-radius: 30px;
}

.payment-method-status {

    .btn-white {
        background-color: #FFF !important;
        border-radius: 30px !important;
        border: none !important;
        font-size: 16px;
        letter-spacing: -0.45px;
    }
}

.btn-white {
    background-color: #EAEAEA !important;
    border-radius: 30px !important;
    border: none !important;
    font-size: 18px;
    letter-spacing: -0.45px;
}

.btn-transparent {
    background-color: transparent !important;
    border-radius: 30px !important;
    border: 1px solid #494646 !important;
    font-size: 18px;
    letter-spacing: -0.45px;
}

section {
    padding: 100px 80px;
}

.navbar-toggler {
    float: right;
}

.shopify-popup {

    .form-control {
        border-top-left-radius: 30px !important;
        border-bottom-left-radius: 30px !important;
        border-top-right-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
        padding: 25px !important;
        border-color: #fff !important;
        background: white !important;
        font-family: $ff-light;
        font-size: 16px;
        color: #000 !important;
    }

    .input-group-text {
        border-top-left-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
        border-top-right-radius: 30px !important;
        border-bottom-right-radius: 30px !important;
        border-left-color: #dfdcdc !important;
        border-color: #fff;
        background: white !important;
        font-family: $ff-light;
        font-size: 16px;
        color: rgb(138, 135, 135) !important;

    }
}

.form-control {
    border-radius: 20px !important;
    border-color: #494646 !important;
    background: #000 !important;
    font-family: $ff-light;
    font-size: 1rem !important;
    color: #000 !important;
}

.navbar {
    background-color: transparent !important;
}

.w-maxcontent {
    width: max-content;
}

.border-integration {
    border: 2px solid #252525;
    border-radius: 20px;
}


.border-top-bottom {
    border-top: 2px solid #252525;
    border-bottom: 2px solid #252525;
}

.image-style {
    border-radius: 20px;
    height: 170px;
}

.footer-text {
    color: #575757;
    font-size: 17px;
    font-weight: 400;
}

.nav-link-space {
    .footer-text:hover {
        background: -webkit-linear-gradient(#00F795, #00C4E9);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 17px;
        font-weight: 400;
    }
}

.bg-works {
    background: URL('../img//Priya_AI/works-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.bg-banner {
    background: URL('../img//Priya_AI/banner-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.bg-footer {
    background: URL('../img//Priya_AI/footer-bg.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.nft-table {
    overflow-x: auto !important;
}

.div-size {
    width: 150px;
}

.nav-close {
    background: #847e77;
    padding: 10px;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center
}

.login-modal {

    .nav-close {
        background: #847e77;
        padding: 10px;
        border-radius: 50%;
        width: 28px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center
    }

    .form-control {
        border-radius: 20px !important;
        border-color: #494646 !important;
        background: #000 !important;
        font-family: $ff-light;
        font-size: 1rem !important;
        color: #fff !important;
    }
}

.cursor-pointer {
    cursor: pointer;
}

.pointer-none {
    pointer-events: none;
}

.rec-dot_active {
    display: none !important;
}

.rec-arrow:hover:enabled,
.rec-arrow:focus:enabled {
    color: #fff;
    background-color: #00F795 !important;
    box-shadow: 0 0 2px 0 #333;
}

.rec-arrow-left:enabled,
.rec-arrow-right:enabled {
    color: #999 !important;
}

.rec-arrow-left:disabled,
.rec-arrow-right:disabled {
    color: #333 !important;
}

.modal-content {
    background: URL('../img//Priya_AI/bg-login.png') !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: 100% !important;
    box-shadow: 0 10px 15px rgba(5, 5, 5, .45) !important
}

.modal-backdrop.show {
    opacity: 0.8 !important;
    background-color: #232325;
}

.login-modal {
    .sign-button {
        min-width: 150px;
        max-width: 150px;
    }

    .form-control {
        border-radius: 50px !important;
    }
}

.marquee {
    animation: my-animation 5s linear infinite;
    animation-play-state: running;
    animation-delay: 0s;
    animation-direction: normal;
}

@keyframes my-animation {
    from {
        -moz-transform: translateX(50%);
        -webkit-transform: translateX(50%);
        transform: translateX(50%);
    }

    to {
        -moz-transform: translateX(-100%);
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
    }
}

.integration-img {
    img {
        max-width: fit-content !important;
    }
}

.modal.show .modal-dialog {
    transform: none !important;
}

.bg-logo {
    background-color: #171717;
    padding: 10px;
    height: 80px;
    width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.integration-modal {
    .modal-content {
        background-color: #fff;
        background: URL('../img//Priya_AI/bg-step.png') !important;
        background-repeat: no-repeat !important;
        background-size: cover !important;
        background-position: 100% !important;
        box-shadow: 0 10px 15px rgba(5, 5, 5, .45) !important;
        border: 3px solid #00C4E9 !important;
        border-radius: 6px !important;
        min-height: 865px;
        max-height: 865px;
    }

    .bg-header {
        background: linear-gradient(to right, #99e9f3, #99f7dc) !important;
        padding: 0px !important;
    }

    .modal-title {
        width: 100%;
        display: flex !important;
        align-items: center !important;
        justify-content: space-between !important;
        padding: 20px !important;
    }
}

.indexedStep {
    color: rgb(190, 190, 190);
    width: 30px;
    height: 30px;
    font-size: 14px;
    font-weight: 600;
    background-color: #000;
    border-radius: 50%;
    border-style: solid;
    border-width: 2px;
    border-color: #515151;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.indexedStep.accomplished {
    background-color: #363636;
    color: #00C4E9;
    border-style: none;
}

.RSPBprogressBar {
    height: 2px;
    width: 75%;
    line-height: 1;
    border-radius: 10px;
    position: relative;
    background-color: #515151;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 0;
    margin: 20px auto;
}

.RSPBprogressBar .RSPBstep {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    z-index: 0;
    position: absolute;
    transform: translateX(-50%);
    transition-property: all;
    transition-timing-function: ease;
    margin-top: -18px;
}

.RSPBprogressBar .RSPBprogressBarText {
    color: #515151;
    font-size: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.RSPBprogressBar .RSPBprogression {
    position: absolute;
    transition: width 0.3s ease;
    left: 0;
    top: 0;
    bottom: 0;
    border-radius: 10px;
    background: #00C4E9;
    z-index: -1;
}

@media screen and (max-width: 480px) {
    .indexedStep {
        width: 15px;
        height: 15px;
        font-size: 6px;
    }
}

.font-bold {
    font-weight: 900;
}

.text-blue {
    color: #00C4E9 !important;
}

.button-white {
    background-color: #fff;
    border: none !important;
    border-radius: 30px;
}

.back-btn {
    background-color: #000;
    border: 2px solid #2c2c2c !important;
    border-radius: 30px;
}

.back-btn:hover {
    background-color: #000;
    border: 2px solid #2c2c2c !important;
    border-radius: 30px;
    color: #00F795 !important;
}

.button-gradient {
    background-color: #000;
    border: none !important;
    border-radius: 30px;
    color: #00F795 !important;
}

.bg-white-products {
    background-color: #fff;
    box-shadow: 0 10px 15px rgba(5, 5, 5, .45) !important;
    min-height: 250px;
    max-height: 250px;
}

.bg-gray-products {
    background-color: #f7f7f7;
}

.bg-black-products {
    background-color: #000;
    min-height: 250px;
    max-height: 250px;
}

.bg-inner-black-products {
    background-color: #000;
}

.step-success {
    background: URL('../img//Priya_AI/bg-step.png') !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: 100% !important;
    box-shadow: 0 10px 15px rgba(5, 5, 5, .45) !important;
    width: 500px;
    height: 350px;
    border-radius: 10px;
    margin-top: 80px;
    margin-bottom: 40px;
}

.position-success-button {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 44px
}

.position-success-content {
    position: absolute;
    left: 0;
    right: 0;
    top: 10%
}

.code-snippet {
    pre {
        background-color: white !important;
        box-shadow: 1px 1px 15px rgba(5, 5, 5, .45) !important;
        overflow-x: hidden !important;
        padding: 30px !important;
        border-radius: 5px;
    }
}

.code-snippet-black {
    pre {
        background-color: white !important;
        box-shadow: 1px 1px 15px rgba(5, 5, 5, .45) !important;
        overflow-x: hidden !important;
        padding: 30px !important;
        border-radius: 5px;

        span {
            padding: 1px;
            background-color: #000 !important;
        }
    }
}

.position-rel {
    position: relative;
}

.position-copy-clipboard-btn {
    position: absolute;
    top: -10%;
    left: 5%;
    box-shadow: 1px 1px 15px rgba(5, 5, 5, .45) !important;
}

.step-modal-hide {
    opacity: 0.2;
}

.step-modal-show {
    opacity: 1 !important;
}

.text-red {
    color: #ec2929 !important;
}

.integration-modal {
    p {
        font-size: 18px !important;
    }

    button {
        font-size: 18px !important;
    }
}

.w-fitcontent {
    width: fit-content !important;
}

.position-abs {
    position: absolute;
    top: 20%;
    left: 0;
    right: 0;
    margin: auto;
}

.graph-items {
    .rec-carousel-item {
        display: flex;
    }
}

//Dashborad

.dashboard-page {
    height: 100vh;
}

.content-view {
    background-color: #f3f4f5 !important;
}

.menu-view {
    min-width: 200px;
    background-color: #fff;
}

.bg-card {
    background-color: #fff;
    box-shadow: 1px 1px 5px rgba(5, 5, 5, .45) !important;
    min-height: 270px;
    max-height: 270px;
}

.tooltip.in {
    opacity: 1;
}

.tooltip.top .tooltip-arrow {
    border-top-color: rgb(87, 86, 86);
    box-shadow: 1px 1px 5px rgba(5, 5, 5, .45);
}

.tooltip-inner {
    box-shadow: 1px 1px 5px rgba(5, 5, 5, .45);
    border-radius: 20px;
    padding-left: 10px;
    padding-right: 10px;
}

.avatar-status-online {
    background-color: green;
}

.avatar-status-busy {
    background-color: red;
}

.avatar-status-away {
    background-color: orange;
}

.avatar-status-offline {
    background-color: gray;
}

.rdt_Table {
    background: none !important;
}

.rdt_TableCell {
    width: fit-content !important;
    padding-right: 0px !important;
}

.rdt_TableRow {
    border-bottom: none !important;
    box-shadow: 1px 1px 5px #8a8b8b !important;
    margin: 8px 3px !important;
    display: flex;
    justify-content: space-between !important;
    padding: 10px 0px;
    width: 99.5% !important;
}


.rdt_TableHead {
    display: none !important;
}

.rdt_TableBody {
    border: none !important;
    box-shadow: none !important;
    width: 100% !important;
}

.bg-gray {
    background-color: #e7e6e6;
}

.floor-btn {
    background-color: #fff;
    border: 2px solid #484848 !important;
    border-radius: 30px;
}

.floor-btn:hover {
    background-color: #000;
    border-radius: 30px;
    color: #00F795 !important;
}

.view-btn {
    background-color: #fff;
    border: none !important;
    border-radius: 30px;
    box-shadow: 1px 1px 3px rgb(87, 86, 86);
    width: fit-content;
}

.view-btn:hover {
    background-color: #000;
    border: none !important;
    border-radius: 30px;
    color: #00F795 !important;
}

.card {
    background: white !important;
}

.custom-switch .custom-control {
    width: 20px !important;
}

.custom-control-input:checked~.custom-control-label::before {
    border-color: #77c03c !important;
    background-color: #77c03c !important;
}

.custom-control-input:checked~.custom-control-label::after {
    border-color: #fff !important;
    background-color: #fff !important;
}

.custom-control-label::before {
    background-color: #d4d4d4 !important;
}

.custom-control-label::after {
    background-color: #fff !important;
}

.custom-control-inline {
    margin-right: 0px !important;
}

.pagination.react-paginate {
    display: flex;
    align-items: center;
    justify-content: center !important;
    position: relative;

    .page-item.prev {
        position: absolute;
        left: 0;
    }

    .page-item.next {
        position: absolute;
        right: 0;
    }
}

.pagination .page-item.active>.page-link,
.pagination .page-item.active>.page-link:focus,
.pagination .page-item.active>.page-link:hover {
    width: fit-content !important;
    background: #000 !important;
    color: #00C4E9 !important;
    font-weight: 600 !important;

}

.pagination .page-item .page-link {
    width: fit-content !important;
    background: transparent !important;
    color: #00C4E9 !important;
    font-weight: 600 !important;
}

.page-item:first-child .page-link {
    border: none !important;
    background: transparent !important;
    font-weight: 400 !important;
}

.page-item:last-child .page-link {
    border: none !important;
    background: transparent !important;
    font-weight: 400 !important;
}

.text-light-gray {
    color: #bcbcbc !important;
}

.number2 {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    font-size: 12px !important;
    text-align: center;
    background: #000;
    color: #00C4E9;
    padding: 2px;
    display: inline-block;
    font-weight: 600;
    position: absolute;
    top: -7px;
    left: 21px
}

.dashboard-search {

    .input-group-text {
        background-color: white !important;
        border: none !important;
        border-top-left-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
        color: #000 !important;
        border-top-right-radius: 20px !important;
        border-bottom-right-radius: 20px !important;
    }

    .form-control {
        background: white !important;
        border: none !important;
        border-top-right-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
        color: #000 !important;
        font-size: 18px !important;
    }

    ::placeholder {
        font-style: italic !important;
        font-size: 16px !important;
    }
}

.centered-progress-bar {
    position: relative;
}

//   .progress {
//     margin-bottom: 0;
//   }

.progress {
    background-color: #363636 !important;
    height: 2.5rem !important;
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.3) !important;
    width: 60%;
}

.progress-value {
    position: absolute !important;
    top: 10px !important;
    left: 48% !important;
    font-size: 16px;
    font-weight: bold;
    color: #000;
}

.progress-bar {
    background-image: linear-gradient(to right, #00C4E9, #00F795);
    border-radius: 15px !important;
    padding-left: 20px;
    padding-right: 20px;
    color: #000;
    font-family: $ff-inter-semi;
    margin: 2px;
    width: 87%;
}

.progress-outer {
    background: #fff;
    border-radius: 45px;
    margin-bottom: 20px;
    position: relative;
}

.text-small {
    font-size: 12px !important;
}

.text-xl {
    font-size: 18px !important;
}

.text-xxl {
    font-size: 20px !important;
}

.input-group-merge {
    box-shadow: 2px 2px 5px #c9cdd1;
    border-radius: 20px !important;
}

.opacity {
    opacity: 0.5;
}

.position-desktop {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 30%;
}

.btn-gray {
    background-color: #000;
    border: none !important;
    border-radius: 30px;
    color: #00F795 !important;
    font-size: 18px;
}

.btn-cancel {
    background-color: #e1e1e1;
    border: none !important;
    border-radius: 30px;
    color: #000 !important;
    font-size: 18px;
}

.btn-gray:hover {
    background-color: #000;
    border-radius: 20px;
    border: none !important;
    color: #00F795 !important;
}

.settings-screen {

    .btn-gray {
        background-color: #000;
        border-radius: 20px;
        border: none !important;
        font-size: 18px;
        width: 200px;
    }

    .btn-gray:hover {
        background-color: #000;
        border-radius: 20px;
        border: none !important;
        color: #00F795 !important;
        width: 200px;
    }
}


.h-100vh {
    height: 100vh;
}

//Payment
.payment-screen {

    h3 {
        font-size: 24px !important;
    }

    p {
        font-size: 16px;
    }


    .payment-card {
        height: 100%;
    }

    .bg-card {
        background-color: #fff;
        box-shadow: 1px 1px 5px rgba(5, 5, 5, .45) !important;
        min-height: fit-content;
        max-height: fit-content;
    }


    .bg-round {
        border: 1px solid #7C7C7C;
        border-radius: 50px;
        width: 313px;
    }

    .bg-round:hover {
        background-color: #000;
        border: 1px solid #000;

        p:first-child {

            background: -webkit-linear-gradient(right, #00F795, #00C4E9);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        border-radius: 50px;
        width: 313px;
    }

}

.text-darkgray {
    color: #282828 !important;
}

.w-40p {
    width: 40%;
}


.text-28 {
    font-size: 20px !important;
}

.text-12 {
    font-size: 12px !important;
}

.text-14 {
    font-size: 14px !important;
}

.text-16 {
    font-size: 16px !important;
}

.text-17 {
    font-size: 17px !important;
}

.text-18 {
    font-size: 18px !important;
}

.text-20 {
    font-size: 20px !important;
}

.text-22 {
    font-size: 22px !important;
}

.text-24 {
    font-size: 26px !important;
}

.payment-modal,
.billing-settings {
    .modal-content {
        background: white !important;
        box-shadow: 0 10px 15px rgba(5, 5, 5, .45) !important;
        border: 3px solid #00C4E9 !important;
        border-radius: 6px !important;
    }

    .bg-header {
        background: linear-gradient(to right, #99e9f3, #99f7dc) !important;
        padding: 0px !important;
    }

    .modal-title {
        width: 100%;
        display: flex !important;
        align-items: center !important;
        justify-content: space-between !important;
        padding: 20px !important;
        font-size: 20px !important;
    }

    .button-white {
        background-color: #fff;
        border: none !important;
        border-radius: 10px;
        box-shadow: 1px 1px 5px rgba(5, 5, 5, .45) !important;
        width: 100%;
    }

    .button-gradient {
        background-color: #000;
        border: none !important;
        border-radius: 10px;
        color: #00F795 !important;
        width: 100%;
    }

    .border-20 {
        border-radius: 20px !important;
    }

    .form-control {
        border-radius: 5px !important;
        border-width: 2px !important;
        border-color: #d8d4d4 !important;
        background: #fff !important;
        color: #000 !important;
        height: 50px;
    }

    .stripe-card-section {
        border: 2px solid #d8d4d4;
        border-radius: 5px !important;
        background: #fff !important;
        color: #000 !important;
        padding: 0 18px;
    }

    ::placeholder {
        font-size: 16px;
        font-family: $ff-inter-medium;
        color: #9e9c9c !important;
    }

    .react-select__control {
        height: 50px;
        border-width: 2px !important;
    }

    .react-select__placeholder {
        font-size: 16px;
        font-family: $ff-inter-medium;
        color: #9e9c9c !important;
    }

    .react-select__indicator-separator {
        display: none !important;
    }

    .react-select__value-container {
        padding-left: 18px !important;
    }
}

.main {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 15px;
    cursor: pointer;
    font-size: 20px;
}

/* Hide the default checkbox */
input[type=checkbox] {
    visibility: hidden;
}

/* Creating a custom checkbox
based on demand */
.geekmark {
    position: absolute;
    top: 6px;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: black;
    border-radius: 5px;
}

/* Specify the background color to be
shown when hovering over checkbox */
.main:hover input~.geekmark {
    background-color: black;
}

/* Specify the background color to be
shown when checkbox is active */
.main input:active~.geekmark {
    background-color: black;
}

/* Specify the background color to be
shown when checkbox is checked */
.main input:checked~.geekmark {
    background-color: #000;
}

/* Checkmark to be shown in checkbox */
/* It is not be shown when not checked */
.geekmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Display checkmark when checked */
.main input:checked~.geekmark:after {
    display: block;
}

/* Styling the checkmark using webkit */
/* Rotated the rectangle by 45 degree and 
showing only two border to make it look
like a tickmark */
.main .geekmark:after {
    left: 7px;
    bottom: 6px;
    width: 6px;
    height: 12px;
    border: solid #00C4E9;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.settings-screen {
    width: 75%;
}

.py-250 {
    padding: 13px 0px;
}


.scroll {
    font-size: 30px;
    font-size: bold;
    color: red;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.scroll:hover {
    color: gray;
    cursor: pointer;
}

.left-scroll {
    position: relative;
    z-index: 10;
    left: -20px;
    top: 77px;
}

.right-scroll {
    position: relative;
    z-index: 10;
    right: 0;
    top: 77px;
}

.box-shadow {
    box-shadow: 1px 1px 5px rgba(202, 201, 201, 0.45) !important;
}

.box-shadow-shopify {
    box-shadow: 1px 1px 20px rgba(202, 201, 201, 0.45) !important;
}

.box-shadow-code {
    box-shadow: 1px 1px 10px rgba(202, 201, 201, 0.45) !important;
}

.react-datepicker__input-container {
    display: flex !important;
    align-items: center !important;

    .react-datepicker__calendar-icon {
        margin: 10px !important;
        padding: 0 !important;
        left: 20px;
        top: 3px;
    }

    input,
    .form-control,
    .react-datepicker-ignore-onclickoutside {
        cursor: pointer !important;
        height: fit-content !important;
        border: none !important;
        border-radius: 20px !important;
        width: 150px !important;
        color: #000 !important;
        font-family: $ff-inter-medium !important;
        margin-left: 5px !important;
    }
}

.price-history-box {
    overflow-x: hidden;
    height: 300px;
    overflow-y: scroll;

    ::-webkit-scrollbar {
        width: 8px !important;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #dddfe0 !important;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #575757;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #575757;
    }
}

.centered-progress-bar {
    .CircularProgressbar-text {
        font-size: 14px !important;
        font-family: $ff-inter-regular;
        transform: translate(1px, 2px);
    }
}

.CircularProgressbar-text {
    font-size: 25px !important;
    font-family: $ff-inter-regular;
    transform: translate(1px, 2px);
}

.nav-tabs.nav-fill {
    border-bottom: 2px solid #474747;
    padding-bottom: 1px;

    .nav-item {
        background: #000;

        a {
            color: #999999;
        }
    }
}

.nav-tabs.nav-fill>.nav-item>a:hover {
    color: #00C4E9 !important;
    border: none !important;
    margin: 0 5px 0 0 !important;
}

.nav-tabs .nav-link {
    border: none !important;
}

.nav-tabs>.nav-item>.nav-link.active {
    border: none !important;
}

.nav-tabs.nav-fill {
    border-bottom: 2px solid #2c2c2c !important;
    padding-bottom: 1px;
}

.active-tab {
    background: #000 !important;
    border-top: 2px solid #2c2c2c !important;
    border-left: 2px solid #2c2c2c !important;
    border-right: 2px solid #2c2c2c !important;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    a {
        color: #00C4E9 !important;
    }
}

.tab-3-mainDiv {
    height: 370px;
    overflow-x: hidden;
    overflow-y: auto;
}

.bg-pricing-box {
    border-radius: 20px;
    background: URL('../img/Pricing/bg-box.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    box-shadow: 1px 1px 5px rgba(202, 201, 201, 0.45) !important;
    min-height: 240px;
    max-height: 240px;
}

.shopify-box {
    background-color: #0f0f0f;

}

.bg-darkgray {
    background-color: #f0f0f0;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.bg-gray2 {
    background-color: #f4f4f4;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;

    .form-control {
        background-color: #fff !important;
        padding-top: 30px;
        padding-bottom: 30px;
        border-radius: 50px !important;
        border-color: #e0dfdf !important;
        color: #000 !important;
        font-size: 20px;
        width: 400px;
    }

    .button-gradient {
        background-color: #000;
        border: none !important;
        border-radius: 30px;
        color: #00F795 !important;
        position: absolute;
        right: 13px;
        top: 10px;
    }

    .price-text-box {
        font-size: 18px;
    }

    button {
        border-radius: 50px;
    }
}

.top-card {
    position: absolute;
    top: -5%;
    left: 8%;
    width: 85%;
    border-radius: 10px;
    box-shadow: 1px 1px 10px rgba(117, 116, 116, 0.45) !important;
}

.card-head {
    margin-top: 300px;
}

.arrows svg {
    padding: 7px;
    background: #fff;
    border-radius: 100%;
    height: 30px;
    width: 30px;
    fill: #000;
    font-weight: 600;
}

.arrows svg:active,
.arrows svg:hover,
.arrows svg:focus,
.arrows svg:focus-visible {
    background: #000;
    fill: #00C4E9;
}

.price-header {
    letter-spacing: 3px;
}

.input-shopify {
    color: white !important;
    border-radius: 0px !important;
    background: #000 !important;
    height: 50px;
    border-color: #2c2c2c !important;
}

section#shadow-host-companion {
    display: none;
}

.input-group-prepend .input-group-text {
    border: 2px solid #d8d4d4 !important;
    border-right: none !important;
}

.floor-price {
    .form-control {
        border-top-left-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
        padding: 14px 18px 10px 2px !important;
    }
}

.btn-research {
    border-radius: 30px !important;
    border: none !important;
    box-shadow: 1px 1px 5px rgba(117, 116, 116, 0.45) !important;
}

.position-coming-soon {
    position: absolute;
    right: 0;
    left: 0;
    margin: auto;
    top: 45%;
    text-align: center;
    font-size: 30px;
    letter-spacing: 3px;
}

.position-coming-soon-code {
    position: absolute;
    right: 0;
    left: 0;
    margin: auto;
    top: 40%;
    text-align: center;
    font-size: 25px;
    letter-spacing: 3px;
}


//Toastify style
.Toastify__toast {
    border: 1px solid #00c1ef !important;
    background: #000 !important;
}

// @keyframes spinner-border {
//     0% {
//         transform: translate3d(-50%, -50%, 0) rotate(0deg);
//     }

//     100% {
//         transform: translate3d(-50%, -50%, 0) rotate(360deg);
//     }
// }


// .spinner-border {
//     display: inline-block;
//     width: 2rem;
//     height: 2rem;
//     vertical-align: text-bottom;
//     border: 0.25em solid currentColor;
//     border-right-color: currentcolor;
//     border-right-color: transparent;
//     border-radius: 50%;
//     animation: spinner-border .75s linear infinite;
// }

// .sr-only {
//     position: absolute;
//     width: 1px;
//     height: 1px;
//     padding: 0;
//     margin: -1px;
//     overflow: hidden;
//     clip: rect(0, 0, 0, 0);
//     white-space: nowrap;
//     border: 0;
// }
#loading {
    display: inline-block;
    width: 30px;
    height: 30px;
    border: 3px solid #000;
    border-radius: 50%;
    border-top-color: #00C4E9;
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
    to {
        -webkit-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    to {
        -webkit-transform: rotate(360deg);
    }
}

.login-modal {
    .password-field {

        .input-group-append,
        .input-group-text {
            background-color: #000 !important;
            border-color: #494646 !important;
            border-top-right-radius: 50px;
            border-bottom-right-radius: 50px;
            color: #ffffff;
            transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
        }

        .form-group .form-control,
        .input-group .form-control {
            padding: 23px !important;
        }

        .input-group-text {
            padding: 10px 18px !important;
        }

        .password-toggle {
            position: absolute;
            right: 15px;
            top: 13px;
        }
    }
}

.settings-password-toggle {
    position: absolute;
    right: 15px;
    top: 55px;
}

.position-info {
    position: absolute;
    right: -7%;
    top: 1%;
}

.position-loading {
    position: absolute;
    left: 0;
    right: 0;
    top: 40%
}

.align-piechart {
    margin-bottom: 2rem;
}

.image-box {
    min-height: 150px;
    max-height: 150px;
}

.bg-products {
    box-shadow: 1px 1px 10px rgba(5, 5, 5, .45) !important;
    min-height: 310px;
    max-height: 310px;

    .product-box {
        min-height: 210px !important;
        max-height: 210px !important;
    }

    .img-box {
        max-width: 100%;
        /* Ensure the image scales proportionally within its parent */
        max-height: 100%;
        /* Ensure the image scales proportionally within its parent */
        width: auto;
        /* Allow the image to scale proportionally */
        height: auto;
        /* Allow the image to scale proportionally */
        position: absolute;
        /* Position the image absolutely within the parent */
        top: 0;
        /* Position the image at the top of the parent */
        left: 7px;
        /* Position the image at the left of the parent */
        right: 0;
        /* Position the image at the right of the parent */
        bottom: 0;
        /* Position the image at the bottom of the parent */
        margin: auto;
        /* Center the image both horizontally and vertically */
    }

}

.bg-products-collecting {
    box-shadow: 1px 1px 10px rgba(5, 5, 5, .45) !important;
    min-height: 250px;
    max-height: 250px;

    .product-box {
        min-height: 210px !important;
        max-height: 210px !important;
    }

    .img-box {
        max-width: 100%;
        /* Ensure the image scales proportionally within its parent */
        max-height: 100%;
        /* Ensure the image scales proportionally within its parent */
        width: auto;
        /* Allow the image to scale proportionally */
        height: auto;
        /* Allow the image to scale proportionally */
        position: absolute;
        /* Position the image absolutely within the parent */
        top: 0;
        /* Position the image at the top of the parent */
        left: 7px;
        /* Position the image at the left of the parent */
        right: 0;
        /* Position the image at the right of the parent */
        bottom: 0;
        /* Position the image at the bottom of the parent */
        margin: auto;
        /* Center the image both horizontally and vertically */
    }

}

.product-info {
    height: 80px;
    /* Set a fixed height for the container */
    overflow: hidden;
    /* Hide any overflowing content */
}

.product-info h4 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    /* Number of lines to show before ellipsis */
    overflow: hidden;
    text-overflow: ellipsis;
}

.price-history {
    height: 150px;
}

.current-price {

    h4 {
        font-size: 20px !important;
    }
}

.bg-white {
    background-color: #fff;
}

.bg-gray {
    background-color: #e7e6e6;
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(90deg);
    }
}

.rotate {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    animation: rotation 10s infinite linear;
}

.nav {
    flex-wrap: nowrap !important;
}

.bg-gray-light {
    background-color: #f7f7f7;
}

.price-history-modal {
    .modal-content {
        height: 500px;
    }
}

.bg-price-box {
    border: 1px solid #ddd9d9;
    position: relative;
    box-shadow: 1px 1px 4px #f1ecec;

    p:first-child {
        position: absolute;
        top: -14px;
        left: 15px;
        background: white
    }
}

.h-100vh {
    height: 100vh;
}

.bg-auth {
    position: relative;
    background: URL('../img/Dashboard/bg-auth.png');
    background-size: contain;
    background-repeat: no-repeat;
}

.bg-left-auth {
    position: relative;
    height: 100%;
    min-height: 100vh;
    background-image: URL('../img/Dashboard/bg-left-auth.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.bg-integration {
    position: relative;
    height: 100%;
    min-height: 100vh;
    background-image: URL('../img/Dashboard/bg-integration.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.login-screen {
    height: 100%;
    min-height: 100vh;
}

.integration-logo {
    padding-top: 34px;
    padding-bottom: 34px;
}

.position-logo {
    position: absolute;
    top: 34px;
}

.position-abs3 {
    position: absolute;
    bottom: 4%;
    left: 0;
    right: 0;
}

.login-modal {
    input {
        min-width: 320px;
        max-width: 320px;
    }
}

.text-green {
    color: #00F795 !important;
}

.button-gray {
    border: 2px solid #2f2f2f !important;
    background: #2f2f2f !important;
    border-radius: 20px;
}

.text-orange {
    color: #ffa800;
}

.text-yellow {
    color: #fdd800;
}

.text-green {
    color: #66c10e;
}

.position-calendar {
    position: absolute;
    left: 10px;
    top: 1px;
}

.flatpickr-input {
    border: 2px solid black;
    border-radius: 16px;
    width: 170px;
    min-width: 170px;
    color: black !important;
}

.border-bottom-blue {
    text-decoration: underline;
}

.continue-btn {
    width: fit-content !important;
}

.navbar .navbar-brand span {
    font-weight: 100 !important;
    font-size: 0.875rem !important;
}

.product-list {
    .progress {
        background-color: #dcdfe2 !important;
        height: 0.5rem !important;
        width: 100%;
    }

    .progress-bar {
        border-radius: 0px !important;
        background: #77c03c !important;
        margin: 0px !important;
    }
}

.input-box-width {
    width: 75%;
}

.loading-text-shadow {
    width: fit-content !important;
    box-shadow: 0px 5px 5px rgb(70, 68, 68), 0px -5px 5px rgb(70, 68, 68);
}

@function shadow-string($color1, $color2, $length) {
    $total-length: $length;
    $string: $color1 0px 0px;

    @while $length>0 {
        $mix-amount: 100 - (($length / $total-length) * 100);
        $mixed-color: mix($color1, $color2, $mix-amount);
        $string-addition: $length+px $length+px;
        $string: $mixed-color $string-addition, $string;
        $length: $length - 1;
    }

    @return $string
}

@mixin longshadow($color1, $color2, $length) {
    text-shadow: shadow-string($color1, $color2, $length);
}

//color vars
$color-bg:#FFEA80;
$color-title:#FFFFD9;
$color-line-1:#FF6138;
$color-line-2:#FFB400;
$color-line-3:#00A388;
$color-line-4:#005AB3;
$color-line-5:#8100FF;

.title-part {
    font-weight: 600;
    font-family: 'Fredoka One', sans-serif;
    text-transform: uppercase;
    display: block;
    color: $color-title;

    &.line-1 {
        @include longshadow($color-line-1, $color-bg, 120);
    }
}

.verify-text {
    background: -webkit-linear-gradient(#131212, #999999);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.wrap-text {
    background: -webkit-linear-gradient(#999999, #131212);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.centered-progress-bar {
    .CircularProgressbar .CircularProgressbar-trail {
        stroke: #212121 !important;
    }
}

.text-gray-50 {
    line-height: 24px;
    font-size: 17px !important;
    color: #6d6d6d !important;
}

.radius-xl {
    border-radius: 50px !important;
}

.expiry-text {
    line-height: 27px;
}


.payment-popup {
    .modal-content {
        background: white !important;
        box-shadow: 0 10px 15px rgba(5, 5, 5, .45) !important;
    }

    .button-gradient {
        background-color: #000;
        border: none !important;
        border-radius: 10px;
        color: #00F795 !important;
        width: 100%;
    }
}

.bg-hide {
    background: #b1adad !important;
    opacity: 0.14 !important;
    pointer-events: none;
}

.position-loader {
    position: absolute;
    right: 0;
    left: 0;
    margin: auto;
    top: 45%;
    text-align: center;
}

.verification-screen {
    .check-mark {
        width: 70px;
    }
}

.green-button {
    background-color: #000 !important;
    border: none !important;
    border-radius: 20px !important;
    color: #00F795 !important;
}

.btn-reconnect {
    width: 150px;
}

.text-gray-shopify {
    color: #423f3f;
}

.shopify-popup {
    .modal-title {
        width: 100%;
        position: relative;
    }

    .close-btn {
        position: absolute;
        right: 0;
        top: 10%;
    }

    .input-group {
        width: 450px;
    }
}

@media (min-width: 992px) {

    .modal-lg,
    .modal-xl {
        max-width: 650px !important;
    }
}

.h-full {
    height: 100%;
}

.flex-grow {
    flex-grow: 1;
}

@media (max-width: 1024px) {
    .form-group {
        width: 50%;
    }
}

@media (max-width: 768px) {
    .form-group {
        width: 100%;
    }
}