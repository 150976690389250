.custom-switch {
  .custom-control-label {
    vertical-align: middle;

    &::before {
      height: 22px;
      width: 45px;
      border: none;
      background-position: center center;
      background-size: cover;
    }

    &::after {
      height: 18px;
      width: 18px;
      background-color: rgba(156, 156, 156, 0.9);
      transition: all 0.25s ease-out;
    }
  }

  .custom-control-input:checked {
    ~.custom-control-label::after {
      background-color: $white;
      transform: translateX(23px);
    }
  }

  &:hover {
    .custom-control-label::after {
      width: 23px;
    }

    .custom-control-input:checked~.custom-control-label::after {
      margin-left: -5px;
    }
  }
}